/* our custom radio button input styles */
.radioButton {
    @apply relative;
}

.radioButton::before, .radioButton::after {
    @apply content-[''] inline-block absolute rounded-full;
}

.radioButton::before {
    @apply w-5 h-5 border border-brand-gray-dark top-0 left-0;
}

.radioButton::after {
    @apply w-3 h-3 top-[4px] left-[4px];
}

.radioButtonChecked::after {
    @apply bg-brand-teal;
}